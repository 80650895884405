import type { ThreeSlidesScrollSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"

import { Layout, LAYOUT_COLOR_BLOCK } from "@jobteaser/ui-kit/components/Layout/Layout"
import { TEXT_COLORS, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { RichText } from "@/modules/kontent/components/RichText"

import styles from "./ThreeSlidesScroll.module.css"

const PREFIX = "ThreeSlidesScrollSectionSlide_"

export type ThreeSlidesScrollPropsType = {
  section: ThreeSlidesScrollSection
}

export const ThreeSlidesScroll: FunctionComponent<ThreeSlidesScrollPropsType> = ({ section }) => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const [currentSlideId, setCurrentSlideId] = useState(`${PREFIX}1`)
  const onObserve: IntersectionObserverCallback = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        setCurrentSlideId(entry.target.id)
        entry.target.scrollIntoView(true)
      }
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onObserve, { threshold: 0.8 })
    let observedValue1: Element | null = null
    let observedValue2: Element | null = null
    let observedValue3: Element | null = null

    if (ref1.current) {
      observer.observe(ref1.current)
      observedValue1 = ref1.current
    }
    if (ref2.current) {
      observer.observe(ref2.current)
      observedValue2 = ref2.current
    }
    if (ref3.current) {
      observer.observe(ref3.current)
      observedValue3 = ref3.current
    }

    return () => {
      if (observedValue1) {
        observer.unobserve(observedValue1)
      }
      if (observedValue2) {
        observer.unobserve(observedValue2)
      }
      if (observedValue3) {
        observer.unobserve(observedValue3)
      }
    }
  }, [])

  return (
    <Layout colorBlock={LAYOUT_COLOR_BLOCK.BLACK}>
      <Layout.Column>
        <section className={styles.main}>
          <div className={styles.context}>
            <RichText
              value={section.title}
              wrapperProps={{
                className: styles.title,
                color: TEXT_COLORS.WHITE,
                resetSpacing: true,
                tag: "div",
                weight: TEXT_WEIGHTS.BOLD,
              }}
            />
            <ul className={styles.labels} data-current-hash={currentSlideId}>
              <li className={clsx(styles.label, { [styles.current]: currentSlideId === `${PREFIX}1` })}>
                {section.text1}
              </li>
              <li className={clsx(styles.label, { [styles.current]: currentSlideId === `${PREFIX}2` })}>
                {section.text2}
              </li>
              <li className={clsx(styles.label, { [styles.current]: currentSlideId === `${PREFIX}3` })}>
                {section.text3}
              </li>
            </ul>
          </div>
          <article className={styles.slide} id={`${PREFIX}1`} ref={ref1}>
            <Image
              src={section.images[0].url}
              alt={section.images[0].description || ""}
              height={section.images[0].height || undefined}
              width={section.images[0].width || undefined}
              sizes="(max-width: 63.999rem) 100vw, 50vw"
            />
          </article>
          <article className={styles.slide} id={`${PREFIX}2`} ref={ref2}>
            <Image
              src={section.images[1].url}
              alt={section.images[1].description || ""}
              height={section.images[1].height || undefined}
              width={section.images[1].width || undefined}
              sizes="(max-width: 63.999rem) 100vw, 50vw"
            />
          </article>
          <article className={styles.slide} id={`${PREFIX}3`} ref={ref3}>
            <Image
              src={section.images[2].url}
              alt={section.images[2].description || ""}
              height={section.images[2].height || undefined}
              width={section.images[2].width || undefined}
              sizes="(max-width: 63.999rem) 100vw, 50vw"
            />
          </article>
        </section>
      </Layout.Column>
    </Layout>
  )
}
